.quick-buttons{
    position: absolute;
    right: 50px;
    top: 20px;
}

.ready-to-shipment {
    .datepicker-range {
        width: 250px;
    }

    .filter-btn {
        // height: 35px !important;
        // margin-top: 1px !important;
        cursor: pointer;

        // .checkboxLabel {
        //     color: black !important;
        //     padding-top: 2px;
        //     font-weight: 600;
        // }

        .form-check {
            padding-top: 2px;
        }
    }

    .dropdown-list {
        height: 350px !important;
    }
}

.invalid {
    border: 1px solid salmon !important;
}

.error-msg {
    text-align: left;
    padding: 10px;
}
.ready-to-ship.popup-container {
    position: relative;
    width: 100%;
    height: 75px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .popup-body {
        position: absolute;
        padding: 25px 15px 25px 35px;
        left: 20%;
        right: 20%;
        bottom: 0;
        top: 0;
        text-align: left;
        margin: auto;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        height: 75px;
        z-index: 100;

        .btn {
            font-size: 14px;
            font-family: 'InterSemiBold';
            font-weight: 600;
            margin-right: 10px;
            color: #6941C6;
            background: #F9F5FF;
            border: none;
        }

        .btn.cancel-order {
            color: #B42318;
            background: none;
            border: 1px solid #FDA29B;
        }

        .close-icon {
            margin-top: 3px;
        }
    }
}

.barcode-scan-modal{
    .modal-dialog{
        width: 480px;
    }   
    .modal-title{
        font-family: 'InterSemiBold';
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
    }
    .userLabel{
        font-family: 'InterMedium';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .check-number{
        height: 80px;
    }
    .modal-footer{
        margin-top: 20px;
        .cancel-btn{
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border: 1px solid #d0d5dd;
            background: white;
            width: 170px;
            height: 44px;
            padding: 10px 18px 10px 18px;
            border-radius: 8px;
            font-family: 'InterSemiBold';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
        .btn-primary{
            height: 44px;
            padding: 10px 18px 10px 18px;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid #7f56d9;
            background: #7f56d9;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            font-family: 'InterSemiBold';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
    }
}
.scan-tooltip{
    width: 160px;
    .popover-body{
        font-family: 'InterMedium';
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #344054;
    }    
}