.track-order {
    background: #F9FAFB;
    // height: 100vh;

    header {
        background: #fff;
    }

    .order-or-tracking-container {
        // overflow: hidden;
        position: absolute;
        width: 100%;
        height: 90%;
        // display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        .tracking-container{
            display: inline-block;
            margin-top: 50px;
            margin-bottom: 20px;
        }
        .title {
            font-family: 'InterSemiBold';
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 30px;
        }

        .container-item {
            background: #fff;
            border: 1px solid #D0D5DD;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            padding: 20px;
            width: 500px;

            .toggle-button {
                margin: 30px 0;

                button {
                    padding: 5px 10px;
                    font-family: 'InterRegular';
                    border-color: #D0D5DD;
                    width: 150px;
                    font-size: 16px;
                    border-style: none;

                    &.order-number {
                        border-radius: 5px 0px 0px 5px;
                    }

                    &.tracking-number {
                        border-left: 0;
                        border-radius: 0px 5px 5px 0px;
                        border-style: none;

                        &:active {

                            border-style: none;
                        }
                    }

                    &.toggle-click {
                        background-color: #9E77ED;
                        color: #fff;
                        border-style: none;

                        &:active {

                            border-style: none;
                        }
                    }
                }
            }

            .intro {
                margin-bottom: 30px;
                font-family: 'InterMedium';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }

            .input-group-item {
                margin-bottom: 30px;

                input {
                    border: none;
                    padding: 10px;
                    border-radius: 10px;
                    outline: 2px solid #D0D5DD;
                    width: 300px;
                }
            }

            .submit-button {
                background-color: #9E77ED;
                color: #fff;
                border-style: none;
                padding: 5px 10px;
                font-family: 'InterRegular';
                border-color: #D0D5DD;
                width: 300px;
                font-size: 18px;
                border-radius: 5px;
                margin-bottom: 30px;

                &:active {
                    border-style: none;
                }

                &[disabled] {
                    opacity: .5;
                }
            }
        }
    }

    .order-or-tracking-detail-container {
        // overflow: hidden;
        // position: absolute;
        margin: 0 auto;
        width: 90%;
        // height: 90%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // text-align: center;

        .title {
            font-family: 'InterSemiBold';
            font-weight: 600;
            font-size: 20px;
            margin: 10px 0 30px;
        }

        .container-left-item {
            width: 30%;
            height: fit-content;
            border: 1px solid #D0D5DD;
            background: #fff;
            padding: 10px;
            margin-right: 20px;
            border-radius: 5px;

            .title {
                font-family: 'InterSemiBold';
                font-weight: 600;
                font-size: 18px;
            }

            .content {
                font-family: 'InterSemiBold';
                font-weight: 600;
                font-size: 14px;
                padding: 0;

                .value {
                    float: right;
                }
            }
        }

        .container-right-item {
            width: 70%;

            .container-1 {
                display: flex;
                justify-content: space-between;
                border: 1px solid #D0D5DD;
                background: #fff;
                padding: 20px 70px;
                margin-right: 20px;
                border-radius: 5px;
                margin-bottom: 10px;
                align-items: center;

                .status {
                    // display: inline-block;
                    // width: 25%;
                    text-align: center;

                    img {
                        display: block;
                        margin: 0 auto;
                    }

                    .status-text {
                        display: block;
                    }

                    .status-date {
                        display: block;
                        color: #98A2B3;
                        display: flex;
                        justify-content: center;

                    }

                    &.active {
                        color: #7F56D9;

                        &+.link-line {
                            background: #7F56D9;
                        }
                    }

                    &:last-child::after {
                        display: none;
                    }
                }

                .link-line {
                    width: 10%;
                    height: 1px;
                    background: #98A2B3;

                    &:last-child {
                        display: none;
                    }

                }

                .canceled {
                    font-family: 'InterSemiBold';
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 38px;
                    color: rgb(225, 9, 45)
                }
            }

            .container-2 {
                border: 1px solid #D0D5DD;
                background: #fff;
                padding: 10px;
                margin-right: 20px;
                border-radius: 5px;
                padding: 10px 30px;

                .head {
                    font-family: 'InterSemiBold';
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                    margin: 5px 0px;
                }

                .status-content {
                    font-family: 'InterMedium';
                    font-size: 14px;
                    line-height: 20px;
                    color: #667085;
                }

                .status {
                    font-family: 'InterSemiBold';
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 38px;
                    color: #FD853A;

                    &.success {
                        color: green
                    }
                }
            }

            .today-value {
                margin-top: 20px;

                .today-date {
                    font-family: 'InterSemiBold';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .today-time {
                    font-family: 'InterSemiBold';
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    color: #667085;
                }
            }
        }

    }
}

@media only screen and (max-width: 768px) {
    .track-order {
        .order-or-tracking-container {
            height: auto;
            overflow: unset;
            margin-top: 30px;
            .container-item {
                width: 95%;
                margin: 0 auto;
            }
        }
        .order-or-tracking-detail-container{
            .d-flex{
                flex-direction: column;
                .container-left-item{
                    width: 100%;
                    padding: 10px;
                    margin-right: 0;
                }
                .container-right-item{
                    width: 100%;
                    .container-1{
                        display: flex;
                        padding: 10px;
                        margin: 20px 0 0 0;
                        .status.active{
                            display: inline-block;
                        }
                        .link-line{
                            display: inline-block;
                            &:last-child{
                                display: none;
                            }
                        }
                    }
                    .container-2{
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }
    
}