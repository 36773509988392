/* src/styles/inter-font.css */

/* Import the Inter font */

body {
    background-color: rgb(249, 250, 251);
}

.main-container {
    max-width: 1440px;
    margin: 0 auto;
    background-color: rgb(249, 250, 251);
}

@font-face {
    font-family: InterRegular;
    font-weight: 500;
    src: url(../assets/fonts/Inter-Regular.eot);
    src: url(../assets/fonts/Inter-Regular.eot?#iefix) format('embedded-opentype'),
        url(../assets/fonts/Inter-Regular.otf) format('otf'),
        url(../assets/fonts/Inter-Regular.woff2) format('woff2'),
        url(../assets/fonts/Inter-Regular.woff) format('woff'),
        url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: InterSemiBold;
    font-weight: 500;
    src: url(../assets/fonts/Inter-SemiBold.eot);
    src: url(../assets/fonts/Inter-SemiBold.eot?#iefix) format('embedded-opentype'),
        url(../assets/fonts/Inter-SemiBold.otf) format('otf'),
        url(../assets/fonts/Inter-SemiBold.woff2) format('woff2'),
        url(../assets/fonts/Inter-SemiBold.woff) format('woff'),
        url(../assets/fonts/Inter-SemiBold.svg#Inter-SemiBold) format('svg'),
        url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: InterMedium;
    font-weight: 500;
    src: url(../assets/fonts/Inter-Medium.eot);
    src: url(../assets/fonts/Inter-Medium.eot?#iefix) format('embedded-opentype'),
        url(../assets/fonts/Inter-Medium.otf) format('otf'),
        url(../assets/fonts/Inter-Medium.woff2) format('woff2'),
        url(../assets/fonts/Inter-Medium.woff) format('woff'),
        url(../assets/fonts/Inter-Medium.svg#Inter-Medium) format('svg'),
        url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

body {
    font-family: "InterRegular", sans-serif !important;
}

.font-insetMedium {
    font-family: 'InterMedium';
    font-weight: 500;
}

.loginLeftside {
    background-color: #ffffff;
}

.loginRightside {
    // background-color: #EEEEEE;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    background-image: url('../assets/loginRIghtSide.jpg'); // Replace 'your-image-url.jpg' with the URL of your image
    background-size: cover; // Cover ensures the background image covers the entire container
    background-position: center; // Center the background image
    background-repeat: no-repeat; // Prevent the background image from repeating
    display: flex;
    justify-content: center;
    align-items: center;

}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #7F56D9;
    --bs-btn-border-color: #7F56D9;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #503789;
    --bs-btn-hover-border-color: #503789;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #503789;
    --bs-btn-active-border-color: #503789;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #7F56D9;
    --bs-btn-disabled-border-color: #7F56D9;
}
.btn-error {
    --bs-btn-color: #fff;
    --bs-btn-bg: #D92D20;
    --bs-btn-border-color: #D92D20;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #D92D20;
    --bs-btn-hover-border-color: #D92D20;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #D92D20;
    --bs-btn-active-border-color: #D92D20;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #D92D20;
    --bs-btn-disabled-border-color: #D92D20;
}

a {
    text-decoration: none;
    color: inherit;
}

.noBorderRight {
    border-right-color: transparent;
}

.noBorderLeft {
    border-left-color: transparent;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
}

.input-group-text {
    background-color: transparent;
}

.form-control {
    font-family: 'InterMedium' !important;
    color: #667085;
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #dee2e6;
    outline: 0;
    box-shadow: none;
}

.form-select {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'InterMedium' !important;
}

.form-select:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #dee2e6;
    outline: 0;
    box-shadow: none;
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: none;
}


.iconSize {
    font-size: 20px;
}

.vh-93-8 {
    height: 91.6vh !important;
}

.vh-100-8 {
    height: 100vh !important;
}

.elementGap {
    position: relative;
    line-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.errorMessage {
    position: absolute;
    margin-top: 3px;
    font-size: 14px;
    color: #F04438;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: none;
}

.card {
    border-radius: 0px;
    margin-bottom: 16px;
}

.displayFlex {
    display: flex;
}

// span.card-icons {
//     background-color: #F4EBFF;
//     width: 30px;
//     height: 30px;
//     border-radius: 6px;
//     display: block;
//     margin-right: 10px;
// }
span.card-icons {
    background-color: #F4EBFF;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.dashboard-card-shape {
    // background-color: #D9D9D9;
    // width: 51px;
    // height: 58px;
    // border-radius: 6px;
    // display: block;
    // margin-right: 10px;
    background-color: #F9F5FF;
    width: 51px;
    height: 58px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.dashboard-card-number-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    font-family: 'InterSemiBold';
}

.dashboard-card-sub-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    font-family: 'InterRegular';
}

.dashboard-card-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    font-family: 'InterSemiBold';
    text-align: left;
}

.card-header {
    background-color: #fff;
}

.card-body {
    min-height: 20vh;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
}

ul.card-link {
    list-style: none;

    & li {
        margin-bottom: 5px;
        line-height: 18px;

    }

    & li a {
        color: #6941C6;
        font-size: 12px;
        font-family: 'InterRegular';
        line-height: 18px;

    }
}

.page-header-text {
    font-size: 20px;
    line-height: 30px;
    color: #000;
    font-weight: 600;
    margin-top: 20px;
}

.checkboxLabel {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    &.unprint-lable {
        font-size: 14px;
        font-weight: 600;
        color: #344054;
        font-family: 'InterSemiBold' !important;
    }
    &.not-serviceable-order {
        font-size: 14px;
        font-weight: 600;
        color: #344054;
        font-family: 'InterSemiBold' !important;
    }
}

.rmdp-input {
    left: 35px;
    display: block;
    height: 35px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rmdp-container {
    display: inline-block;
    height: 100%;
    width: 100%;

}

.filter-btn {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
    margin-right: 10px;
    text-align: center;
    width: fit-content;
    // width: 100% !important;
    // font-size: 14px !important;
}

.filter-btn:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
}

.cancel-btn {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
    margin-right: 10px;
    text-align: center;
    width: 100% !important;
    font-size: 14px !important;
}

.cancel-btn:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
}

.popup-container {
    position: fixed !important;
    width: 100% !important;
    height: 75px;
    top: unset !important;
    left: 5% !important;
    right: 0 !important;
    bottom: 5% !important;
    z-index: 3 !important;

    .filter-btn {
        border-radius: 8px;
        box-shadow: none;
    }

    .cancel-button {
        color: #B42318 !important;
        background: none;
        border: 1px solid #FDA29B;
    }
}

.popup-body {
    position: absolute;
    padding: 25px 15px 25px 35px;
    left: 25%;
    right: 25%;
    bottom: 0;
    top: 0;
    text-align: left;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 75px;
    z-index: 9999;
}

//Error
iframe {
    display: none !important;
}

.razorpay-container iframe {
    display: block !important;
}
/* DropdownDialog.css */
.dropdown-dialog {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;

}

.dropdown-list {
    display: block;
    position: absolute;
    margin-top: 10px !important;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 999;
    min-width: 232px;
    width: 100%;
    // height: 564px;
    border-radius: 8px;
    // overflow-y: scroll;

}

.dropdown-list li {
    position: relative;
    padding: 0;
}

.accordion-button {
    width: 100%;
    background-color: #f1f1f1;
    color: #333;
    border: none;
    text-align: left;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-button.active {
    background-color: #ddd;
}

// .accordion-content {
//     display: none;
//     padding: 8px 16px;
//     border-top: 1px solid #ccc;
// }

.accordion-button.active+.accordion-content {
    display: block;
}

.space-between {
    justify-content: space-around;
    margin: 0px 20px;
}


.accordion-content {
    padding: 1em 1.5em;
}

.title {
    margin: 0 12px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.open {
    padding: 0;
    border: 0;
    margin: 0;
}

.arrow-dw {
    transform: rotate(180deg);
    float: right;
    color: rgb(0, 0, 0);
}

.arrow {
    float: right;
}

.menuLink {
    margin-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.MuiPaper-root {
    z-index: 1000 !important;
}

.grid-checkbox-popuop {

    // &.all-order{        
    &.popup-container {
        height: unset;
        width: 641px !important;
        left: 35% !important;
    }

    .popup-body {
        width: 100%;
        height: unset;
        position: unset;
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
        border: 1px solid rgba(234, 236, 240, 1);
        padding: 14px;
        border-radius: 8px;

        .left-container {
            display: inline-block;
            width: 30%;

            .selected-item {
                font-family: 'InterSemiBold';
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: left;

            }
        }

        .right-container {
            display: inline-block;
            width: 70%;
            text-align: right;

            .close-icon {
                padding: 0 5px;
            }

            .filter-btn {
                color: #6941C6;
                font-family: 'InterSemiBold';
                font-size: 14px;
                font-weight: 600;
                background-color: #F9F5FF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: 1px solid rgba(249, 245, 255, 1);
                border-radius: 8px;
            }

            .cancel-button {
                color: #B42318 !important;
                background: none;
                border: 1px solid #FDA29B;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: 1px solid rgba(253, 162, 155, 1);
                border-radius: 8px;
            }
        }
    }

    // }
}

.quickAccess-btn {
    background-color: #fff;
    color: #344054;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
    margin-right: 10px;
    font-size: 14px;
    padding: 10px 16px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 10px;
}

.save-button {
    text-align: right;
    padding: 10px;
}

.mb-0 {
    margin-bottom: 0;
}

.mapboxgl-map {
    height: 500px !important;
    border-radius: 10px;
}

.p-le-10 {
    padding: 0 10px;
}

.product-table .ag-selection-checkbox {
    margin: 0px 10px 5px 0;
}

.ag-theme-alpine .ag-center-cols-viewport .grid-link {
    font-weight: 600;
    font-size: 14px;
    color: #7F56D9;
    font-family: 'InterSemiBold';
    margin-top: 10px;
}

.filter-section {

    // div{
    //     display: inline-block !important;
    // }
    .filter-left-container {
        display: inline-block;
        width: 13%;
    }

    .filter-right-container {
        width: 87%;
        display: inline-block;
        text-align: right;

        .scan-btn {
            display: inline-block;
            margin-right: 20px;
            width: 44px;
            .filter-btn{
                margin: 0 !important;
            }
        }

        .unprint {
            display: inline-block;
            margin-right: 20px;

            button {
                margin-right: 0;
            }
        }
        .not-serviceable {
            display: inline-block;
            margin-right: 20px;

            button {
                margin-right: 0;
            }
        }

        .datepicker-class {
            width: 248px;
            display: inline-block;
            margin-right: 20px;
        }

        .export-btn {
            display: inline-block;
            margin-left: 20px;
        }

        .dropdown-dialog-notService {
            display: inline-block;
            margin-left: 20px;
            margin-right:5px;
            width: 250px;

          
            .notService-filter-btn {
                // height: 37px;
                height: 39px;
                padding-bottom: 5px;
                margin-bottom: 3px;
                border: 1px solid #fda29b;
                box-shadow: 0 0 0 4px rgba(248, 215, 218, 0.5); /* Shadow effect */
                cursor: pointer;
                transition: box-shadow 0.3s ease;
                background-color: #fff;
                border-radius: 5px;
                //box-shadow: none;
                text-align: left;
                width: 219px;
            }
            
            .accordion-wrapper {
                text-align: left;

                .dropdown-list {
                    .menuLink {
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: 600;
                        font-family: 'InterSemiBold' !important;
                    }

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        font-family: 'InterSemiBold' !important;
                    }

                    .checkboxLabel {
                        font-family: 'InterMedium';
                        color: #344054;

                        .form-check-input {
                            margin-top: 3px;

                            &:checked {
                                background-color: #7F56D9;
                                border: #7F56D9;
                            }
                        }
                    }

                    .inputWithIcon {
                        width: 100%;
                        border: none;
                        background: #F2F4F7;
                        border-radius: 0;
                        color: #D0D5DD;
                    }
                    .clear-all-btn {
                        width: 106px;
                        height: 36px;
                        border-radius: 8px;
                        font-family: 'InterMedium';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #667085;
                        padding: 0;
                    }

                    .apply-filter-btn {
                        border: 1px solid rgba(249, 245, 255, 1);
                        width: 106px;
                        height: 36px;
                        border-radius: 8px;
                        font-family: 'InterSemiBold';
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #6941C6;
                        padding: 0;
                        background-color: #F9F5FF;
                    }

                }
            }
        }
        .dropdown-dialog-parent {
            display: inline-block;
            margin-left: 4px;
            margin-right: 70px;

            .filter-btn {
                // height: 37px;
                height: 39px;
                padding-bottom: 5px;
                margin-bottom: 3px;
                border: 1px solid #D0D5DD;
                border-radius: 5px;
                box-shadow: none;
                text-align: center;
            }
            

            .accordion-wrapper {
                text-align: left;

                .dropdown-list {
                    .menuLink {
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: 600;
                        font-family: 'InterSemiBold' !important;
                    }

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        font-family: 'InterSemiBold' !important;
                    }

                    .checkboxLabel {
                        font-family: 'InterMedium';
                        color: #344054;

                        .form-check-input {
                            margin-top: 3px;

                            &:checked {
                                background-color: #7F56D9;
                                border: #7F56D9;
                            }
                        }
                    }

                    .inputWithIcon {
                        width: 100%;
                        border: none;
                        background: #F2F4F7;
                        border-radius: 0;
                        color: #D0D5DD;
                    }

                    .clear-all-btn {
                        width: 106px;
                        height: 36px;
                        border-radius: 8px;
                        font-family: 'InterMedium';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #667085;
                        padding: 0;
                    }

                    .apply-filter-btn {
                        border: 1px solid rgba(249, 245, 255, 1);
                        width: 106px;
                        height: 36px;
                        border-radius: 8px;
                        font-family: 'InterSemiBold';
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #6941C6;
                        padding: 0;
                        background-color: #F9F5FF;
                    }
                }
            }
        }
    }

    .inputWithIcon,
    .datepicker-range {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        font-family: 'InterRegular' !important;
        height: 40px;
        width: 269px;
        // margin: auto;
        box-shadow: none;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }

    .filter-btn {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        font-family: 'InterRegular' !important;
        height: 40px;
        box-shadow: none;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
    }
}

.daterangepicker {
    .ranges {
        ul {
            li {
                color: #667085;
                font-size: 12px;
                font-weight: 400;
                font-family: 'InterRegular' !important;
            }
        }
    }
}

.return-request-modal {
    .modal-content {
        .modal-header {
            padding-bottom: 0;

            .modal-title {
                font-family: 'InterSemiBold';
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                color: rgba(16, 24, 40, 1);
                padding-bottom: 0;
            }
        }

        .modal-body {
            padding-top: 10px;

            .checkboxLabel {
                font-family: 'InterMedium';
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: rgba(52, 64, 84, 1);
            }
        }

        .modal-footer {
            .cancel-btn {
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: 1px solid rgba(208, 213, 221, 1);
                background: rgba(255, 255, 255, 1);
                width: 170px;
                height: 44px;
                padding: 10px 18px 10px 18px;
                gap: 8px;
                border-radius: 8px;
                font-family: 'InterSemiBold';
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

            .btn-primary {
                width: 170px;
                height: 44px;
                padding: 10px 18px 10px 18px;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid rgba(127, 86, 217, 1);
                background: rgba(127, 86, 217, 1);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                font-family: 'InterSemiBold';
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;

            }
        }
    }

}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: 1px solid;
    border-color: transparent !important;
    border-style: none !important;
    outline: initial;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper .ag-input-field-input:focus-visible {
    box-shadow: none;
}

.product-sort{
    .dropdown-list{
        text-align: left;
    }
}
.product-inventory{
    .dropdown-list{
        text-align: left;
        #filter-text-box{
            background: #f2f4f7;
            border: none;
            border-radius: 0;
            color: #d0d5dd;
            width: 100%;
        }
        .other-value{
            width: 204px;
            height:28.56px;
            padding: 6.28px 8.79px 6.28px 8.79px;
            gap: 5.03px;
            border-radius: 5.03px 0px 0px 0px;
            border: 0.63px 0px 0px 0px;
            opacity: 0px;
            font-family: "InterRegular";
            font-size: 10.05px;
            font-weight: 400;
            line-height: 15.08px;
            text-align: left;

        }
    }
}



// File Upload

.file-upload {
    background-color: #ffffff;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .uploaded-img{
        img{
            width: 125px;
        }
    }
  }
  
  .file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1FB264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824B;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  
  .file-upload-content {
    display: none;
    text-align: center;
    width: 80%;
  }
  
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
  
  .image-upload-wrap {
    // margin-top: 20px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 2px 0px #0000000D;
    position: relative;
    background: #f9fafb;
    border-radius: 8px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  
//   .image-dropping,
//   .image-upload-wrap:hover {
//     background-color: #1FB264;
//     border: 4px dashed #ffffff;
//   }
  
  .image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
  }
  
  .drag-text {
    text-align: center;
    width: 40%;
    p{
        font-family: 'InterRegular';
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        color: #828282;
    }
  }
  
  .drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #15824B;
    padding: 60px 0;
  }
  
  .file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
  }
  
  .remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .remove-image:hover {
    background: #c13b2a;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
  }
  
  .remove-image:active {
    border: 0;
    transition: all .2s ease;
  }

  			
  // Checkbox Hand pointer

  input[type="checkbox"] {
    cursor: pointer;
  }

  .custom-line-height-wrapper {
    display: flex;
    line-height: 1.2;      
    text-align: justify;
    word-break: break-word;
  }
