.billing-page{
    .billin-card {
        // margin-top: 30px;
    
        .card {
            border-radius: 8px;
    
            .card-header {}
    
            .card-body {
                padding: 16px 45px;
                display: flex;
                justify-content: space-between;
    
                .left-container {
                    .advanced-section {
                        font-family: 'InterSemiBold';
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 28px;
                        text-align: left;
                    }
    
                    .billing-cyle {
                        font-family: InterSemiBold;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: left;
                        color: #667085;
                        display: block;
                    }
    
                    .billin-date {
                        font-family: InterSemiBold;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        color: #101828;
                    }
                }
    
                .right-container {
                    .total-text {
                        font-family: InterSemiBold;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: left;
                        color: #667085;
    
                        .amount {
                            //styleName: Display sm/Semibold;
                            font-family: Inter;
                            font-size: 30px;
                            font-weight: 600;
                            line-height: 38px;
                            text-align: left;
                            color: #101828;
    
                        }
                    }
    
                }
    
                .past-bill {
                    //styleName: Text xl/Semibold;
                    font-family: InterSemiBold;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    text-align: left;
    
                }
            }
        }
    }
    .download {
        font-family: InterRegular;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #6941C6;
        text-decoration: underline;
    }
    
    .paynow-btn {
        width: 84px;
        height: 36px;
        padding: 8px 14px 8px 14px;
        border-radius: 8px;
        background-color: #FEF3F2;
        border: 1px solid #FEF3F2;
        box-shadow: 0px 1px 2px 0px #1018280D;
        font-family: 'InterRegular';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #B42318;
    }
    .bill-titles{
        //styleName: Text xl/Semibold;
        font-family: 'InterSemiBold';
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #000000;
        margin: 20px 0;
    }
    .app-order-status{
        .cancel{
            background-color: #FEF3F2;
            border: 1px solid #FEF3F2;
            color: #B42318;
        }
    }
}


