.product-popup {
    width: 700px !important;

    .popup-body {
        width: 100%;
    }

    &.popup-container {
        position: relative;
        width: 100%;
        height: 75px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .popup-body {
            position: absolute;
            padding: 25px 15px 25px 35px;
            left: 25%;
            right: 25%;
            bottom: 0;
            top: 0;
            text-align: left;
            margin: auto;
            border-radius: 15px;
            border-color: black;
            background: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            height: 75px;
            z-index: 9999;

            .btn {
                font-size: 14px;
                font-family: 'InterSemiBold';
                font-weight: 600;
                margin-right: 10px;
                color: #6941C6;
                background: #F9F5FF;
                border: none;
            }

            .btn.cancel-order {
                color: #B42318;
                background: none;
                border: 1px solid #FDA29B;
            }

            .close-icon {
                margin-top: 3px;
            }
        }
    }
    
}

.action-buttons.product-popup {
    width: 170px;
}

.product-table {
    .filter-section .filter-right-container{
        width: 62%;
    }
    .pagination.top-pagination{
        top: 62px;
    }
    .ag-theme-alpine .ag-cell {
        margin-top: 0;
    }

    .ag-selection-checkbox {
        margin: 5px 10px 5px 0;
    }

    .ag-cell-value {
        margin: 5px 0;
    }

    .ag-theme-alpine .ag-center-cols-viewport {
        .grid-link {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            display: inline-block;
            line-height: 1.5;
            white-space: unset;
            word-break: break-word;
        }

        .status-value {
            display: inline-block;
            line-height: 1.5;
            white-space: unset;
            word-break: break-word;
        }
    }
}

.d-align-right {
    justify-content: flex-end;
}

.productBadge {
    background-color: #F2F4F7 !important;
    color: #344054;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
}

.datepicker-range {
    padding-left: 30px;
    color: #667085;
    font-size: 14px;
    height: 38px;
}

