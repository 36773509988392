.order-detail-page-product-grid {
    .ag-theme-alpine {
        .ag-cell {
            margin-top: 5px;

            .ag-cell-value {
                line-height: 18px;
                font-family: InterSemiBold;
                color: #101828;
                font-weight: 600;
                font-size: 12px;
            }
        }
    }

    .footerTotal {
        margin-top: -5px;

        .totel-text {
            display: inline-block;
            width: 88%;
            text-align: right;
            font-family: InterRegular;
            color: #475467;
            font-weight: 400;
            font-size: 12px;
        }

        .total-amount {
            display: inline-block;
            width: 12%;
            font-family: InterSemiBold;
            color: #101828;
            font-weight: 600;
            font-size: 12px;
            padding: 0 17px;
        }
    }
}

.order-detail-callstatus {
    .call-status {}
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    color: #7F56D9;
    background-color: transparent;
    border-bottom: 2px solid #7F56D9;
    font-size: 14px;
    font-family: 'InterSemiBold';
    cursor: pointer;
}

.nav-tabs .nav-link {
    border: none;
    color: #98A2B3;
    border-bottom: none;
    font-size: 14px;
    font-family: 'InterSemiBold';
    cursor: pointer;
}