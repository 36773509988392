.order-detail-page-product-grid {
    .ag-theme-alpine {
        .ag-header {
            border-bottom: 1px solid #EAECF0;
            border-radius: 0;
            margin-bottom: 0;
        }

        .ag-cell {
            margin-top: 5px;

            .ag-cell-value {
                line-height: 18px;
                font-family: InterSemiBold;
                color: #101828;
                font-weight: 600;
                font-size: 12px;
                word-break: break-word;
                margin-bottom: 5px;
            }
        }
    }

    .footerTotal {
        margin-top: -5px;

        .totel-text {
            display: inline-block;
            width: 88%;
            text-align: right;
            font-family: InterRegular;
            color: #475467;
            font-weight: 400;
            font-size: 12px;
        }

        .total-amount {
            display: inline-block;
            width: 12%;
            font-family: InterSemiBold;
            color: #101828;
            font-weight: 600;
            font-size: 12px;
            padding: 0 17px;
        }
    }
}

.order-detail-callstatus {
    .call-status {}
}

.order-detail-page-product-grid {
    .ag-theme-alpine {
        .ag-floating-bottom-container .ag-cell {
            display: none;

            &.total-sum {
                display: block;
            }

            &.total-count {
                display: block;
                width: 200px !important;
                margin-left: -110px;
                text-align: right;

                .ag-cell-value {
                    color: #475467;
                    font-family: 'InterRegular';
                    font-weight: 400;
                    line-height: 18px;
                    font-size: 12px;
                }

            }
        }
        .ag-header .ag-header-cell-label{
            //styleName: Text xs/Medium;
            font-family: 'InterMedium';
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #98A2B3;
        }
    }
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container {
    min-height: 100px;
}

.timeline-event {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 1px;
        background-color: #F2F4F7;
        top: 5px;
        bottom: -30px;
        left: 3%;
        margin-left: -3px;
    }
    &:last-child{
        &::before {
           display: none;
        }
    }

    .timeline-content {
        .timeline-title {
            font-family: 'InterSemiBold';
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #1D2939;
        }

    }
}
.back-button{
    margin-bottom: 10px;
    display: inline-block;
}