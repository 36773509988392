.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    /* Adjust the size of the checkbox */
    margin-right: 15px;
    /* Space between checkboxes */
    cursor: pointer;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    /* Size of the circular checkbox */
    width: 20px;
    /* Size of the circular checkbox */
    background-color: #EAECF0;
    // border: 2px solid #000;
    /* Border color of the checkbox */
    border-radius: 50%;
    /* Makes it a circle */
}

.checkbox-container:hover input~.checkmark {
    background-color: #EAECF0;
    /* Change background color on hover */
}

.checkbox-container input:checked~.checkmark {
    background-color: #D1FADF;
    /* Background color when checkbox is checked */
    background-color: #D1FADF;
    // border: 2px solid;
    /* Border color when checkbox is checked */
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 7px;
    /* Adjust the position of the checkmark inside the circle */
    top: 2px;
    /* Adjust the position of the checkmark inside the circle */
    width: 6px;
    height: 12px;
    border: solid #039855;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    /* Rotates the checkmark */
}


.all-done-page {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 80%;

    .container {
        width: 440px;
        text-align: center;
        margin: 0 auto;
    }
}

.icon {
    position: absolute;
    left: 10px;
    top: 50%;

    & img {
        position: relative;
        top: 5px;
    }

    & i {
        position: relative;
        top: 6px;
    }

}

.inputWithIcon {
    padding-left: 35px;
    // width: 269px;
}

.headerLogo {
    width: 40px;
    height: 100%;
}

.pageLogo {
    width: 60px;
    height: 100%;
}

.forgotPassword {
    font-size: 16px;
    color: #1570EF;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
}

.loginLeftside {
    .titles {
        .h1-title {
            font-family: 'InterSemiBold';
            font-weight: 600;
            line-height: 44px;
            font-size: 36px;
            color: #101828;
        }

        .title-description {
            font-family: 'InterSemiBold';
            font-weight: 500;
            // line-height: 44px;
            font-size: 16px;
            color: #667085;
            margin-bottom: 0;
        }
    }
}

.header-logo {
    width: 200px;
}

.logo-alone {
    width: 100px;
}

.label {
    font-family: 'InterMedium';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #344054;
}

.daterangepicker .ranges li.active{

    background-color: #6941c6 !important;
  }
  
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #6941c6 !important;
  }
  
  .react-responsive-modal-closeButton{
      top: 20px !important;
      right: 20px !important;

  }

  .modal-dialog-Custom {
    max-width: 635px !important;
    margin: 2rem auto !important;
    max-height: 400px !important;
}

