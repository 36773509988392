.parent-div{
    margin:20px;
    width:100%;
}
.topview{
    display: flex !important;
    justify-content: space-between;
}
.fw-600{
    font-weight: 600;
}
.quickBtn{
    font-weight:600;
    font-size:14px;
    border:1px solid #D0D5DD;
    border-radius:5px;
    padding:5px 10px; 
    color:#344054;
}
.syncBtn{
    font-weight:600;
    font-size:14px;
    border:1px;
    border-radius:5px;
    padding:5px 10px;
    background-color: #7F56D9;
    color:white;
    margin-left:10px;
}
.tabItem{
    color:#98A2B3;
    font-weight:500;
    text-transform:none;
    min-width:auto;
    padding:1px 10px;
    font-size:14px;
}

.divider{
    color:#D0D5DD
}
