.tracking-page {
    .warming-text {
        color: red;
        font-family: 'InterRegular';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    .error-message{
        text-align: right;
    }
    .tracking-details {
        .content {
            .headr {
                font-family: 'InterSemiBold';
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }

            .desc {
                font-family: 'InterRegular';
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .card-detail {
            .card-header {
                .orderDetailTitle {
                    font-family: 'InterMedium';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                }

            }

        }
    }

    .settings {
        .header {
            justify-content: space-between;
            align-items: center;
        }

        .copy-continer {
            position: relative;
            display: inline;

            img {
                position: absolute;
                right: 10px;
                top: 3px;
                cursor: pointer;
            }
        }

        .enable-disable {
            border-radius: 8px;
            border: none;
            padding: 5px 10px;
        }

        .link {
            width: 75%;
            display: inline-block;
            border: 2px solid #D0D5DD;
            border-radius: 8px;
            padding: 5px 10px 5px 10px;
        }

        .how-to-setup {
            background-color: #F9F5FF;
            color: #6941C6;
            font-family: 'InterSemiBold';
            font-size: 14px;
            font-weight: 600;
            outline: none;
            border: none;
            line-height: 20px;
            padding: 10px;
            border-radius: 8px;
            margin-left: 10px;
        }
    }

    .appearance-settings-input {
        position: relative;
        margin-bottom: 20px;

        label {
            display: block;
            font-family: 'InterMedium';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
        }

        .color {
            width: 25px;
            height: 25px;
            border: none;
            position: absolute;
            top: 26px;
            left: 18px;
        }

        .color-text {
            width: 100%;
            display: inline-block;
            border: 2px solid #D0D5DD;
            border-radius: 8px;
            padding: 5px 10px 5px 30px;
        }
    }

    .aditional-css {
        label {
            display: block;
            font-family: 'InterMedium';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
        }

        textarea {
            width: 100%;
            border: 2px solid #D0D5DD;
            border-radius: 8px;
            height: 100px;
        }
    }

    .button-lable-desc {
        margin-bottom: 20px;

        label {
            display: block;
        }

        input {
            width: 100%;
            border: 2px solid #D0D5DD;
            border-radius: 8px;
            padding: 5px 10px;
        }
    }

    .preview {
        color: #039855;
        &.disable{
            color: #D92D20 !important;
            background-color: transparent !important;
        }
        &.enable{
            color: #039855 !important;
            background-color: transparent !important;
        }
    }

    .required {
        color: red;
    }
    .disable{
        background-color: #D92D20 !important;
        color: #fff;
    }
    .enable{
        background-color: #039855 !important;
        color: #fff
    }
}