.quick-buttons {
    position: absolute;
    right: 50px;
    top: 20px;
}

.modal-header {
    font-family: 'InterSemiBold';
    color: #101828;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    display: block;

    p {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #101828 !important;
    }

    .error {
        color: red;
        font-size: 12px;
    }


}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-body {
    .imgBox {

        width: 96px;
        height: 96px;
        border-radius: 8px;
        background-color: #D9D9D9;
    }

    .pTitle {
        color: #7F56D9;
        font-family: 'InterSemiBold';
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .pSubTitle {
        color: #667085;
        font-family: 'InterSemiBold';
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .priceBox {
        border-radius: 5px;
        border-color: inherit;
    }
}