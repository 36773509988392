.manage-billing-page {
    .billin-card {
        // margin-top: 30px;

        .card {
            border-radius: 8px;

            .card-header {
                .displayFlex {
                    justify-content: space-between;

                    .setting-card-title {
                        .card-icons {
                            display: inline-block;
                        }
                    }

                    .change-plan {
                        border: 1px solid #D0D5DD;
                        box-shadow: 0px 1px 2px 0px #1018280D;
                        //styleName: Text sm/Semibold;
                        font-family: 'InterSemiBold';
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        text-align: left;
                        color: #344054;
                    }
                }

            }

            .card-body {
                padding: 16px 45px;
                display: flex;
                justify-content: space-between;

                .card-sub-title {
                    //styleName: Text xs/Semibold;
                    font-family: 'InterSemiBold';
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: left;
                    color: #667085;
                }

                .left-container {
                    .advanced-section {
                        display: flex;
                        font-family: 'InterMedium';
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32px;
                        text-align: left;

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        .advance-text {
                            display: flex;
                            flex-direction: column;
                            padding-left: 5px;
                        }

                        .amount-text {
                           
                            //styleName: Text xl/Semibold;
                            font-family: 'InterSemiBold';
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            text-align: left;

                            .inr-text {
                                //styleName: Text sm/Medium;
                                font-family: Inter;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                color: #667085;
                            }
                        }
                    }
                    .billing-cycle-container{
                        display: flex;
                        .billing-cyle {
                            padding-left: 10px;
                            display: flex;
                            flex-direction: column;
                            font-family: InterSemiBold;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;
                            text-align: left;
                            color: #667085;
                            .billing-date {
                                font-family: InterSemiBold;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                color: #101828;
                            }
                        }
    
                        
                    }
                    
                }

                .right-container {
                    .total-text {
                        font-family: InterSemiBold;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: left;
                        color: #667085;

                        .amount {
                            //styleName: Display sm/Semibold;
                            font-family: Inter;
                            font-size: 30px;
                            font-weight: 600;
                            line-height: 38px;
                            text-align: left;
                            color: #101828;

                        }
                    }

                }

                .past-bill {
                    //styleName: Text xl/Semibold;
                    font-family: InterSemiBold;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    text-align: left;

                }
            }
        }
    }

    .download {
        font-family: InterRegular;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #6941C6;
        text-decoration: underline;
    }

    .paynow-btn {
        width: 84px;
        height: 36px;
        padding: 8px 14px 8px 14px;
        border-radius: 8px;
        background-color: #FEF3F2;
        border: 1px solid #FEF3F2;
        box-shadow: 0px 1px 2px 0px #1018280D;
        font-family: 'InterRegular';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #B42318;
    }

    .bill-titles {
        //styleName: Text xl/Semibold;
        font-family: 'InterSemiBold';
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #000000;
        margin: 20px 0;
    }
}