ul.userList {
    list-style: none;

    & li {

        position: relative;
        padding: 15px 0px;
        border-bottom: 1px solid #EAECF0;
        margin-bottom: 10px;
    }


    & h4.user-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-top: 5px;
        margin-bottom: 0px;
    }

    .user {
        align-items: center;
    }

}

p.details {
    font-size: 12px;
    line-height: 18px;
}

img.arrow {
    margin-top: 10px;
}


ul.userList li:last-child {

    border-bottom: none;

}


div.avathar {
    background-color: #F9F5FF;
    width: 48px;
    height: 48px;
    border-radius: 200px;
    display: flex;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;

}

span.avathar-text {
    color: #7F56D9;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    font-weight: 500;
    display: block;
    padding: 10px;
    font-family: 'InterMedium';
}

.no-padding {
    padding: 0px;
}

.cardTitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

p.innerTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
}

p.innerContent {
    font-size: 14px;
    line-height: 20px;
}

.cardBodyInner {
    margin-left: 48px;
}

.saveButton {
    margin-right: 8px;
    width: 170px;
}

.cancelButton {
    margin-right: 8px;
    width: 170px;
    background-color: #fff;
    color: #050505 !important;
    border: #b1b1b1 !important;
    font-weight: 300;
}

.deleteButton {
    background-color: #fff !important;
    color: #f7291a !important;
    border-color: #FDA29B !important;
    font-weight: 300;
}

.userLabel {
    font-size: 12px;
    line-height: 18px;
    font-family: 'InterMedium';
    color: #344054;
    ;
}

.cardBodyInner {
    .disabled {
        pointer-events: none;
        opacity: .5;
        cursor: not-allowed;
    }
}
.user-permission-modal{
    .modal-header{
        width: 94%;
        display: inline-block;
        margin-bottom: 10px;
    }
}
