.pagination {
    position: relative;
    padding: 20px 0;
    justify-content: center;

    .page-item {
        &.selected {
            a {
                background: #F2F4F7;
                border: none;
                color: #7F56D9;
                font-size: 14px;
                border-radius: 8px;
            }
        }

        a {
            background: transparent;
            border: blanchedalmond;
            color: #667085;
            font-size: 14px;
        }
    }

    .page-item:first-child {
        position: absolute;
        left: 0;

        &.disabled {
            a {
                opacity: .6;
            }
        }

        img {
            width: 12px;
            height: 12px;
            margin-top: -0.6px;
        }

        a {
            padding: 7px 10px;
            border-radius: 10px;
            background: #fff;
            color: #344054;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 14px;
            font-family: 'InterRegular';
            font-weight: 500;
            line-height: 20px;
        }
    }

    .page-item:last-child {
        position: absolute;
        right: 0;

        &.disabled {
            a {
                opacity: .6;
            }
        }

        img {
            width: 12px;
            height: 12px;
        }

        a {
            color: #344054;
            padding: 7px 10px;
            border-radius: 10px;
            background: #fff;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 14px;
            font-family: 'InterRegular';
            font-weight: 500;
            line-height: 20px;
        }
    }
}


.ag-theme-alpine {
    .ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active{
        outline: none;
        box-shadow: unset;
    }
    
    .cell-wrap-text {
        white-space: normal !important;
    }

    .customers {
        .address-wrap {
            .customer-name {
                font-size: 16px;
                margin-bottom: 5px;
            }

            .address {
                font-size: 12px;
            }
        }
    }

    .address-wrap {
        // line-height: 1;
        margin-bottom: 10px;
        margin-top: 5px;

        .customer-name {
            color: #1D2939;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            font-family: 'InterMedium';
            font-weight: 500;
            font-size: 14px;
            color: #667085;
            line-height: 20px;
        }
    }

    .app-order-status {
        .default-status {
            background-color: #EFF8FF;
            display: inline-flex;
            align-items: center;
            line-height: 18px;
            padding: 5px 9px;
            border-radius: 2rem;
            font-size: 12px;
            color: #175CD3;
            font-weight: 500;
            font-family: 'InterMedium';
            text-transform: capitalize;
            word-break: break-word;
        }

        .cancel {
            background-color: #FFFAEB;
            display: inline-flex;
            align-items: center;
            line-height: 18px;
            padding: 5px 9px;
            border-radius: 2rem;
            font-size: 12px;
            color: #B54708;
            font-weight: 500;
            font-family: 'InterMedium';
            text-transform: capitalize;
        }

        .refund {
            background-color: #FFFAEB;
            display: inline-flex;
            align-items: center;
            line-height: 18px;
            padding: 5px 9px;
            border-radius: 2rem;
            font-size: 12px;
            color: #B54708;
            font-weight: 500;
            font-family: 'InterMedium';
            text-transform: capitalize;
        }

        .delivered {
            background-color: #ECFDF3;
            display: inline-flex;
            align-items: center;
            line-height: 18px;
            padding: 5px 9px;
            border-radius: 2rem;
            font-size: 12px;
            color: #027A48;
            font-weight: 500;
            font-family: 'InterMedium';
            text-transform: capitalize;
        }
    }

    .ag-cell {
        align-items: flex-start;
        // margin-top: 15px;
    }

    .d-flex-center {
        display: flex;
        // margin: 5px 0;
        // justify-content: center;
        align-items: flex-start;
    }

    .success {
        background-color: #F0F9FF;
        display: inline-flex;
        align-items: center;
        line-height: 18px;
        padding: 5px 9px;
        border-radius: 2rem;
        font-size: 12px;
        color: #026AA2;
        font-weight: 500;
        font-family: 'InterMedium';
        text-transform: capitalize;
    }

    .info {
        background-color: #F2F4F7;
        display: inline-flex;
        align-items: center;
        line-height: 18px;
        padding: 5px 9px;
        border-radius: 2rem;
        font-size: 12px;
        color: #344054;
        font-weight: 500;
        font-family: 'InterMedium';
        text-transform: capitalize;
    }

    .fulfilled {
        background-color: #F2F4F7;
        display: inline-flex;
        align-items: center;
        line-height: 18px;
        padding: 5px 9px;
        font-size: 12px;
        color: #344054;
        font-weight: 500;
        font-family: 'InterMedium';
        text-transform: capitalize;
        border-radius: 5px;
    }

    .unfulfilled {
        background-color: #F2F4F7;
        display: inline-flex;
        align-items: center;
        line-height: 18px;
        padding: 5px 9px;
        font-size: 12px;
        color: #344054;
        font-weight: 500;
        font-family: 'InterMedium';
        text-transform: capitalize;
        border-radius: 4px;
    }

    .ag-root-wrapper {
        background: transparent;
        border: none;
    }

    .ag-header {
        margin: 20px 0;
        border: none;
        background: #ffffff;
        border-radius: 10px;

        .ag-header-cell-label {
            color: #667085;
            font-family: 'InterMedium';
            font-weight: 500;
            font-size: 14px;
        }
    }

    // Grid Body
    .ag-center-cols-viewport {
        background: #ffffff;
        border-radius: 10px;

        .ag-row-odd {
            background: #ffffff;
        }

        .check-box-container {
            .ag-cell-wrapper {
                align-items: flex-start;

                .ag-selection-checkbox {
                    height: 35px;
                }

                .ag-cell-value {
                    margin-top: -5px;
                }
            }
        }

        // .ag-cell-wrapper {
        //     padding-top: 10px;
        // }

        .order-number {}

        .address {
            line-height: 18px;
            white-space: unset;
            word-break: break-word;
        }

        .grid-link {
            font-weight: 600;
            font-size: 14px;
            color: #7F56D9;
            font-family: 'InterSemiBold';
        }

        .order-date {
            font-size: 12px;
            line-height: 18px;
            color: #98A2B3;
            margin-bottom: 8px;
        }

        .logo {
            line-height: 18px;
            margin-bottom: 0;
            // img {
            //     width: 60px;
            // }
        }

        .viewMore {
            // display: inline-block;
            font-weight: 500;
            font-size: 12px;
            color: #7F56D9;
            text-decoration: underline;
            cursor: pointer;
            margin-bottom: 20px;
            line-height: 18px;
            font-family: 'InterRegular';
            margin-top: 10px;
        }
    }


    .call-status-block {
        .call-count-status {
            position: relative;
            margin-right: 5px;
            cursor: pointer;

            .count {
                position: absolute;
                top: -10px;
                right: 2px;
                background-color: #42307D;
                color: white;
                border-radius: 3px;
                font-size: 10px;
                height: 12px;
                width: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'InterRegular';
                font-weight: 500;
            }
        }

        .call-status {
            color: #4D4D4D;
            height: 30px;
            font-family: 'InterRegular';
            font-weight: 500;
            border: 1px solid #D8D8D8;
            border-radius: 5px;
        }

        .status-text {
            display: inline-flex;
            align-items: center;
            line-height: 16px;
            padding: 5px 9px;
            font-size: 10px;
            color: #231F20;
            font-weight: 500;
            font-family: 'InterRegular';
            border-radius: 10px;

            &.accepted {
                background-color: #ADE1AD;
            }

            &.declined {
                background-color: #FFC6DA;
            }

            &.other {
                background-color: #FFEDBE;
            }
        }

    }

    .price_tag {
        // font-weight: 500 !important;
        // color: #000 !important;
        // font-family: 'InterSemiBold' !important;

        span {
            margin-top: 10px;
        }
    }

    .carrier-data {
        .ag-cell-value {
            line-height: 20px;
        }

        .carrier {
            font-family: 'InterMedium';
            color: #667085;
            text-transform: capitalize;
            line-height: 20px;
            margin: 0;
            margin-top: 5px;
        }

        .delivery-id {
            color: #7F56D9;
            text-decoration: underline;
            font-weight: 500;
            font-family: 'InterMedium';
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            cursor: pointer;
        }
    }

    .more-action {
        img {
            width: 36px;
            cursor: pointer;
            margin-top: 5px;
        }
    }

    .order-tag-status {
        background: #FFE482;
        padding: 0px 10px;
        border-radius: 5px;
        font-family: 'InterRegular';
        font-weight: 500;
        display: inline-block;
        line-height: 1.5;
        white-space: unset;
        word-break: break-word;
        font-size: 12px;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

.z-index-2000 {
    // z-index: 2000;
    width: 200px;
}

.produc-list {
    .popover-body {
        max-height: 311px;
        overflow-y: auto;
    }

    .product-list-container {
        line-height: 18px;
        border-bottom: 1px solid #D0D5DD;
        padding: 10px 0;

        b {
            color: #344054;
        }

        p {
            margin: 0;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.action-buttons {
    width: 160px;

    // .popover-arrow{
    //     display:  none;
    // }
    .popover-body {
        button {
            background: none;
            border: none;
            color: #344054;
            font-family: 'InterMedium';
            font-weight: 500px;
            line-height: 20px;
            font-size: 14px;
            padding: 10px 5px;

            &:hover {
                outline: 1px solid gray;
            }
        }

        a {
            background: none;
            border: none;
            color: #344054;
            font-family: 'InterMedium';
            font-weight: 500px;
            line-height: 20px;
            font-size: 14px;
            // padding-left: 5px;
            display: block;
            padding: 10px 5px;

            &:hover {
                outline: 1px solid gray;
            }
        }

        .cancel-order {
            color: #B42318;
        }
    }
}

.datepicker-range {
    padding-left: 30px;
    color: #667085;
}

.modal-header {
    font-family: 'InterSemiBold';
    color: #101828;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

.modal {
    .modal-content {
        border: none;
    }

    .modal-footer {
        border: none !important;
    }

    .modal-sm {
        --bs-modal-width: 400px !important;
    }

    .modal-md {
        --bs-modal-width: 614px !important;
    }
}

.blueLight {
    width: 109px;
    border-radius: 16px;
    padding: 2px 8px 2px 10px;
    background-color: #F0F9FF !important;
    color: #026AA2;
    font-family: 'InterMedium';
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.Indigo {
    width: 81px;
    border-radius: 16px;
    padding: 2px 8px 2px 10px;
    background-color: #EEF4FF !important;
    color: #3538CD;
    font-family: 'InterMedium';
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.Pink {
    width: 64px;
    border-radius: 16px;
    padding: 2px 8px 2px 10px;
    background-color: #FDF2FA !important;
    color: #C11574;
    font-family: 'InterMedium';
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.btnWithImg {
    height: 20px;
    width: 20px;
}

.orderDetailTitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #000;
    font-family: 'InterMedium';
}

.card-header {
    background-color: #fff;
    border-bottom: 1px solid #EAECF0;
}

.card-body {
    p {
        font-family: 'InterRegular';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #101828;
    }

    .textLabel {
        color: #667085;
        font-size: 12px;
        font-family: 'InterSemiBold';
        font-weight: 600;
        line-height: 18px;
        margin-right: 5px;
    }

    .labels {
        background-color: #F2F4F7 !important;
        color: #344054;
        font-size: 12px;
        font-family: 'InterMedium';
        font-weight: 500;
        margin-right: 5px;
    }

    .linkUpdate {
        font-family: 'InterRegular';
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #7F56D9;
        text-decoration: underline;
        vertical-align: middle;
        text-align: center;
        position: relative;
        margin-top: 10px;
        margin-left: 10px;

    }

    .tagLabels {
        background-color: #F2F4F7 !important;
        color: #344054;
        font-size: 14px;
        font-family: 'InterMedium';
        font-weight: 500;
        margin-right: 5px;
        text-transform: capitalize;
        margin-bottom: 10px;
        display: inline-block;
    }
}

.card-footer {
    background-color: #fff;
    border-top: 1px solid #EAECF0;
}

ul.suggestions {
    position: absolute;
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.3);
    z-index: 999;
    background: #fff;
    display: block;
    width: 90%;
}

ul.suggestions li {
    position: relative;
    cursor: pointer;
    background-color: #fff;
}

/* Timeline.css */
.activity-card {
    overflow-y: scroll;
    height: 350px;
}

.timeline {
    display: flex;
    flex-direction: column;
    // width: 300px;
    position: relative;
}

.timeline::before {
    // content: '';
    position: absolute;
    width: 1px;
    background-color: #F2F4F7;
    top: 5px;
    bottom: 0;
    left: 2.5%;
    margin-left: -3px;
    height: 70%;
}

.timeline-event {
    display: flex;
    margin-bottom: 20px;
    width: 265px;
}

.timeline-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #B3B3B3;
    margin-right: 20px;
    top: 5px;
    position: relative;

    &::after {
        border-left: 1px solid;
        position: relative;
    }
}

.timeline-content {
    display: flex;
    flex-direction: column;
}

.timeline-date {
    margin-right: 10px;
    color: #344054;
    font-family: 'InterRegular';
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.timeline-setting-courier-model-title {
    font-weight: 600;
    color: #1D2939;
    font-family: 'InterSemiBold';
    font-size: 12px;
    line-height: 18px;
}



// .timeline {
//     display: flex;
//     flex-direction: column;
//     width: 300px;
//     border-left: 1px solid #F2F4F7;
//     padding-left: 20px;
// }

// .timeline-event {
//     margin: 10px 0;
//     display: block;
//     align-items: center;
// }

// .timeline-date {
//     margin-right: 10px;
//     color: #344054;
//     font-family: 'InterRegular';
//     font-size: 12px;
//     line-height: 18px;
//     font-weight: 400;
// }

// .timeline-setting-courier-model-title  {
//     font-weight: 600;
//     color: #1D2939;
//     font-family: 'InterSemiBold';
//     font-size: 12px;
//     line-height: 18px;
// }

.loader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, .6);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
        width: 300px;
    }
}

#pie {
    height: 250px;
}

#stackChart {
    height: 600px;
}

.state-tooltip {
    height: 150px;

    .caption {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        font-family: 'InterMedium';
        margin-left: 13px;
    }

    .title {
        font-size: 12px;
        font-family: 'InterSemiBold';
        font-weight: 700;
        line-height: 18px;
        text-align: left;
    }

    sup {
        font-size: 0.8em;
        vertical-align: super;
        line-height: 0;
    }
}

#reasonChart {
    height: 150px;
}

.courier-head {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    font-family: 'InterSemiBold';
    line-height: 20px;
}

.courier-card-head {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    font-family: 'InterSemiBold';
    line-height: 24px;
}

.setting-card-title {
    margin: 0 12px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 600;
    cursor: default;
    font-family: 'InterSemiBold';
}

.setting-courier-model-label {
    font-size: 14px;
    line-height: 20px;
    font-family: 'InterMedium';
    color: #344054;
    font-weight: 500;

    &.courier-checkbox {
        font-size: 16px;
        width: 150px;
        margin: 20px auto;
    }
}

.setting-courier-model-title {
    font-size: 18px;
    line-height: 28px;
    font-family: 'InterSemiBold';
    color: #101828;
    font-weight: 600;
}

/* switch for courier integration */

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 40px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100%;
}

input:checked+.slider {
    background-color: #7F56D9;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.content {
    padding: 16px;
    display: block;

    .title {
        font-size: 12px;
        font-weight: 600;
        font-family: 'InterSemiBold';
        color: #667085;
        line-height: 18px;
        text-align: left;
        margin-bottom: 4px;
        margin-left: 0px;
        cursor: default;

    }

    .data {
        font-size: 14px;
        font-weight: 400;
        font-family: 'InterRegular';
        color: #101828;
        line-height: 20px;
        text-align: left;

    }

    .link {
        font-size: 12px;
        font-weight: 500;
        font-family: 'InterMedium';
        color: #6941C6;
        line-height: 18px;
        text-align: left;
    }
}

input:checked+.slider+.content {
    display: block;
}

.chennalBox {
    width: 269px;
    height: 253px;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background-color: #fff;
    padding: 20px;
    margin: 20px;

    .box-status {
        position: relative;
        display: flex;
        justify-content: end;
        padding: 0px 0px 15px 0px;
    }

    .box-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
    }

    .centered-image {
        max-width: 100%;
        max-height: 100%;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        font-family: 'InterSemiBold';
        color: #000;
        line-height: 24px;
        position: relative;

    }
}

.file-input-container {
    display: flex;
    align-items: center;

    .browse-button {
        background-color: #ffffff;
        color: #000;
        border: 1px solid #ccc;
        padding: 3px 16px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 14px;
    }

    .file-input {
        display: none;
    }
}

.error {
    font-size: 12px !important;
    color: red !important;
    font-family: 'InterRegular' !important;
    margin-bottom: 0px !important;
    position: absolute !important;
}

.billing-header {
    text-align: center;
    color: #6941C6;
    font-size: 20px;
    font-family: 'InterSemiBold';
    font-weight: 600;
    line-height: 30px;
}

.billing-price {
    text-align: center;
    color: #000000;
    font-size: 30px;
    font-family: 'InterSemiBold';
    font-weight: 600;
    line-height: 38px;
}

.billing-short-desc {
    text-align: center;
    color: #667085;
    font-size: 14px;
    font-family: 'InterRegular';
    font-weight: 400;
    line-height: 20px;
}

.billing-desc {
    text-align: center;
    color: #667085;
    font-size: 14px;
    font-family: 'InterRegular';
    font-weight: 400;
    line-height: 20px;
}

.billing-layout {
    width: 1072px;
    min-height: 176px;
    background-color: #F9F5FF;
    padding: 44px 64px 44px 64px;
    justify-content: space-between;
    display: flex;
    position: relative;
    margin-left: 5%;

    .header {
        font-family: 'InterSemiBold';
        font-size: 24px;
        font-weight: 600;
        line-height: 20px;
        color: #42307D;
    }

    .desc {
        font-family: 'InterRegular';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #6941C6;
    }
}

.single-row-data .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport,
.single-row-data .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.single-row-data .ag-theme-alpine .ag-layout-print .ag-center-cols-viewport,
.single-row-data .ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.single-row-data .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-viewport,
.single-row-data .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.single-row-data .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-viewport,
.single-row-data .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container {
    min-height: 60px !important;
}

.dropdown-toggle::after {
    display: none;
}

.menu-btn {
    background-color: #fff;
    color: #000;
    border: none;
    box-shadow: none;
    margin-right: 0px;
}

.customer-info {
    padding: 40px;

    .info-header {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterRegular';
        color: #4D4D4D;
        margin-bottom: 4px;
    }

    .info-content {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        font-family: 'InterSemiBold';
        color: #231F20;
        margin-bottom: 4px;
    }

    .right-border {
        position: relative;
    }

    .right-border::after {
        content: '';
        position: absolute;
        background-color: #B3B3B3;
        width: 1px;
        height: 90%;
        bottom: 0;
        right: 55px;
    }

    .right-border:last-child::after {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 3px;
        height: 90%;
        bottom: 0;
        right: 55px;
    }
}

.customer {

    .deatils-header {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #000;
        margin-bottom: 4px;
    }

    .customer-content {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #667085;
    }

    .customer-link {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #7F56D9;
        margin-bottom: 4px;
    }
}

.last-placed-order {

    .order-number {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        font-family: 'InterSemiBold';
        color: #7F56D9;
        margin-bottom: 4px;
    }

    .order-desc {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterMedium';
        color: #667085;
        margin-bottom: 4px;
    }

    .order-amount {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        font-family: 'InterSemiBold';
        color: #000;
        margin-bottom: 4px;
    }

    .order-desc-one {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterMedium';
        color: #000000;
        margin-bottom: 4px;
        margin-top: 10px;
    }

    .order-amount-one {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #000;
        margin-bottom: 4px;
    }

    .view-orders {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterRegular';
        color: #7F56D9;
    }

}

.all-orders {
    .total {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        font-family: 'InterRegular';
        color: #667085;
    }

    .total-amount {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #101828;
    }
}

.customer-filter-dialog {
    .header {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
        color: #344054;
        margin-left: 10px;
    }

    .btns {
        color: #667085;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterMedium';
    }

    .apply-btn {
        background-color: #F9F5FF;
        color: #6941C6;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        font-family: 'InterSemiBold';
    }

    .tags {
        color: #344054;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterMedium';
        padding: 5px 15px;
        margin-bottom: 10px;
    }
}

hr {
    margin: 1rem 0;
    color: #ccc;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}

.order-tag-status {}

.return-accept-popup {
    .modal-header {
        flex-direction: column;
        align-items: flex-start;

        .modal-title {
            font-family: 'InterSemiBold';
            font-size: 18px;
            font-weight: 600;
            color: #101828;
            line-height: 28px;
        }

        .sub-title {
            font-family: 'InterSemiBold';
            font-size: 14px;
            font-weight: 500;
            color: #101828;
            line-height: 20px;
        }
    }
}

.return-reason {
    .ag-cell-value {
        color: #667085;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'InterMedium';
        margin-top: 5px;
    }
}

.ag-checkbox .ag-checkbox-input-wrapper::after{
    color: transparent;
    border: 1px solid #D0D5DD;
    background: none;
    border-radius: 3px;
}

.ag-checkbox .ag-checkbox-input-wrapper.ag-checked::after {
    color: #ffffff;
    background: #7F56D9;
    border: 1px solid #7F56D9;
    // border-radius: 8px;
}

.courier-delete-popup {
    .modal-footer {
        .cancel-btn {
            width: fit-content !important;
            padding: 9px 20px;
            border-radius: 8px;
            font-family: 'IntersemiBold';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        .clear-now-btn {
            width: fit-content !important;
            border-radius: 8px;
            padding: 9px 20px;
            background-color: #D92D20;
            font-family: 'InterSemiBold';
        }
    }
}

.courier-modal {
    .modal-footer {
        .cancel-btn {
            // width: fit-content !important;
            padding: 9px 20px;
            border-radius: 8px;
            font-family: 'IntersemiBold';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        .save-btn {
            // width: fit-content !important;
            border-radius: 8px;
            padding: 9px 20px;
            // background-color: #D92D20;
            font-family: 'InterSemiBold';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
    }
}

.parent-div {
    margin-left: 300px;
}

.pagination.top-pagination {
    justify-content: right;
    position: absolute;
    right: 10px;
    top: 108px;

    .page-item {
        display: none;
    }

    .page-item:first-child {
        display: block;
        position: unset;

        &.disabled {
            a {
                opacity: .5;
            }

        }

        a {
            border-radius: 8px 0px 0px 8px;
            border: 1px solid #D0D5DD;
            height: 38px;
            // border-right: none;
            outline: none;
        }
    }

    .page-item:last-child {
        display: block;
        position: unset;

        &.disabled {
            a {
                opacity: .5;
            }

        }

        a {
            border-radius: 0px 8px 8px 0px;
            border: 1px solid #D0D5DD;
            height: 38px;
            outline: none;
        }
    }
}

.custom-cell-line-height {
    line-height: 1.2;
    /* Adjust the value as needed */
      .product-table{
          .filter-section .filter-right-container .datepicker-class.product-page-datepicker{
              margin-right: 90px;
        }
        
        .top-pagination {
            justify-content: right;
            position: absolute;
            right: 10px;
            top: 62px;
        }
    }
}

.disabled{
    .pagination{
        .page-item{
            pointer-events: none;
            opacity: .5;  
            a {
                pointer-events: none;
                opacity: .5; 
            }      
        }
    }
}
.d-block{
    display: block !important;
}