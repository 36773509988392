.manifest-opopu.popup-container {
    position: relative;
    width: 100%;
    height: 75px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .popup-body {
        position: absolute;
        padding: 25px 15px 25px 35px;
        left: 21%;
        right: 21%;
        bottom: 0;
        top: 0;
        text-align: left;
        margin: auto;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        height: 75px;
        z-index: 9999;

        .btn {
            font-size: 14px;
            font-family: 'InterSemiBold';
            font-weight: 600;
            margin-right: 10px;
            color: #6941C6;
            background: #F9F5FF;
            border: none;
        }

        .btn.creat-manifest {
            color: #ffffff;
            background: #7F56D9;
            border: 1px solid #7F56D9;
        }

        .close-icon {
            margin-top: 3px;
        }
    }
}
.action-buttons.manifest-opopu{
    width: 150px;
}
.Ag-grid-manifest{
    .ag-theme-alpine .ag-cell {
        align-items: flex-start;
        margin-top: unset;
        padding: 10px 17px;
    }
    .warehouse-location{
        margin: 10px 0;
        font-family: 'InterMedium';
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        line-height: 18px;
        white-space: unset;
        word-break: break-word;
        text-transform: capitalize;
    }
    .carrier-data {
        margin: 10px 0;
        .ag-cell-value {
            line-height: 20px;
        }
    
        .carrier {
            font-family: 'InterMedium';
            color: #667085;
            text-transform: capitalize;
            line-height: 20px;
            margin: 0;
        }
    
        .delivery-id {
            color: #7F56D9;
            text-decoration: underline;
            font-weight: 500;
            font-family: 'InterMedium';
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            cursor: pointer;
        }
    }
}

.action-buttons.manifest-order{
    width: 200px;
}