// SideBar
.shopping-card {
  width: 200px;
  // height: 30px;
  margin-right: 7px;
}

.orderlytics {
  font-weight: 700;
  color: "black";
}

.menulist {
  margin-top: 20px;
  border-bottom: 1px solid #EAECF0;
  padding-bottom: 15px;

  &:last-child {
    border-bottom: none;
  }

  .menu-label {
    font-size: 10px;
    color: #98A2B3;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .menuItem-parent {
    display: flex !important;
    margin-left: 10px;
    border-radius: 8px;
    // height: 30px !important;
    padding: 8px 16px;

    &.active {
      background-color: #F4EBFF;
      color: #6941C6 !important;
    }

    &:active {
      background-color: #F4EBFF;
    }

    &:focus {
      background-color: #F4EBFF;
    }

    .menu-logo {
      width: 20px;
      height: 20px;
      margin: 4px;

      &:active {
        color: #6941C6 !important;
      }

      &:focus {
        color: #6941C6;
      }
    }

  }

}

.menu-image {
  width: 40px;
  height: auto;
}

.menu-username {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'InterSemiBold';
  margin-bottom: 2px;
}

.menu-username-role {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'InterRegular';
  margin-bottom: 2px;
}

.d-flex {
  display: flex;
}

.p-20 {
  padding: 20px;
}

.menu-item {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;


  &:active {
    color: #6941C6 !important;
  }

  &:focus {
    color: #6941C6 !important;
  }
}

span.menu-item.active {
  color: #6941C6 !important;
  font-size: 14px;
  font-weight: 500;
  // margin-top: 2px;
}

ul#menu {
  color: #999;
  display: none;
  line-height: 30px;
  list-style: none;
  // margin: 20px 10px;
  text-align: left;
  background-color: #fff;
  width: 95%;
  margin-bottom: 45px;
  padding-left: 20px;

  li {
    width: 100%;
    display: block;
    padding: 10px;
    box-shadow: 1px 1px 2px 2px rgba(101, 100, 100, 0.46);
    border-radius: 6px;
  }

  a {
    padding-left: 10px;
    width: 100%;
    display: block;
  }

}

.profile-container {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px;
  background-color: #F4EBFF;
  cursor: pointer;

  .avathar {
    .user-name {
      text-transform: uppercase;
    }
  }
}

.profile-container-logout {
  display: flex;
  position: absolute;
  bottom: 95px;
  right: 0;
  left: 0;
  padding: 10px 10px 10px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 200px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  z-index: 22;
  background: #fff;

  #logout-id {
    font-family: 'InterMedium';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #344054;
    display: block;
    width: 100%;

    img {
      margin-right: 7px;
    }
  }
}

.side-container {
  position: fixed;
  background: #fff;
  height: 100vh;
  .side-inner-container {
    position: relative;
    height: 90%;
    width: 280px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
}