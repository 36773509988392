.plan-details {
    .plan-list {
        position: relative;

        .card-header.active {
            position: absolute;
            top: -48px;
            height: 46px;
            background: #7F56D9;
            width: 100%;
            border-radius: 8px 8px 0 0;
            text-align: center;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid #7F56D9;
            outline: 1px solid #7F56D9;

            p {
                //styleName: Text md/Semibold;
                font-family: InterSemiBold;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                margin: 0;
            }
        }

        .plan-button {
            background-color: #E9D7FE;
            color: #53389E;
            border: 1px solid #E9D7FE;
            padding: 11px;
            box-shadow: 0px 1px 2px 0px #1018280D;
            //styleName: Text md/Semibold;
            font-family: InterSemiBold;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            &.active {
                background-color: #7F56D9;
                box-shadow: 0px 1px 2px 0px #1018280D;
                border: 1px solid #7F56D9;
                color: #FCFCFD;
            }
        }
    }

}