.customer-popup {
    width: 700px !important;

    .popup-body {
        width: 100%;
    }

    &.popup-container {
        position: relative;
        width: 100%;
        height: 75px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .popup-body {
            position: absolute;
            padding: 25px 15px 25px 35px;
            left: 25%;
            right: 25%;
            bottom: 0;
            top: 0;
            text-align: left;
            margin: auto;
            border-radius: 15px;
            border-color: black;
            background: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            height: 75px;
            z-index: 9999;

            .btn {
                font-size: 14px;
                font-family: 'InterSemiBold';
                font-weight: 600;
                margin-right: 10px;
                color: #6941C6;
                background: #F9F5FF;
                border: none;
            }

            .btn.cancel-order {
                color: #B42318;
                background: none;
                border: 1px solid #FDA29B;
            }

            .close-icon {
                margin-top: 3px;
            }
        }
    }
}

.action-buttons.customer-popup {
    width: 170px;
}

.customer-table {
    .ag-theme-alpine .ag-cell {
        margin-top: 0;
    }

    .ag-selection-checkbox {
        margin: 5px 10px 5px 0;
    }

    .ag-cell-value {
        margin: 5px 0;
    }

    .customer-address {
        line-height: 2
    }
}

.timeline-content {
    .timeline-title {
        font-size: 12px;
        font-family: 'InterSemiBold';
        font-weight: 600;
        line-height: 18px;
    }

    .timeline-date {
        font-size: 12px;
        font-family: 'InterRegular';
        font-weight: 400;
        line-height: 18px;
    }
}

.move-btns {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    box-shadow: 0px 0px 2px 1px #ccc;
    height: 36px;
    width: 36px;
    border-radius: 8px;
    padding: 8px, 14px;
    gap: 8px;
    margin-right: 5px;
}

.ag-cell {
    font-weight: 500 !important;
}

.popup-containers {
    position: fixed !important;
    width: 700px !important;
    height: 75px;
    top: unset !important;
    left: 5% !important;
    right: 0 !important;
    bottom: 5% !important;
    z-index: 3 !important;
}